import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Profiles from '../views/utilities/Profiles';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// notice routing
const PersonList = Loadable(lazy(() => import('views/pages/person/List')));
const PersonManager = Loadable(lazy(() => import('views/pages/person/Manager')));

// ploy routing
const PloyList = Loadable(lazy(() => import('views/pages/ploy/List')));
const PloyManager = Loadable(lazy(() => import('views/pages/ploy/Manager')));

// campaign routing
const CampaignList = Loadable(lazy(() => import('views/pages/campaign/List')));
const CampaignManager = Loadable(lazy(() => import('views/pages/campaign/Manager')));

// notice routing
const NoticeList = Loadable(lazy(() => import('views/pages/notice/List')));
const NoticeManager = Loadable(lazy(() => import('views/pages/notice/Manager')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/home',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/person',
            element: <PersonList profiles={Profiles.Administrator} />
        },
        {
            path: '/person/manager',
            element: <PersonManager profiles={Profiles.Administrator} />
        },
        // {
        //     path: '/customer',
        //     element: <PersonList profile={Profiles.Customer} />
        // },
        // {
        //     path: '/customer/manager',
        //     element: <PersonManager profile={Profiles.Customer} />
        // },
        // {
        //     path: '/partner',
        //     element: <PersonList profile={Profiles.Partner} />
        // },
        // {
        //     path: '/partner/manager',
        //     element: <PersonManager profile={Profiles.Partner} />
        // },
        {
            path: '/ploy',
            element: <PloyList />
        },
        {
            path: '/ploy/manager',
            element: <PloyManager />
        },
        {
            path: '/campaign',
            element: <CampaignList />
        },
        {
            path: '/campaign/manager',
            element: <CampaignManager />
        },
        {
            path: '/notice',
            element: <NoticeList />
        },
        {
            path: '/notice/manager',
            element: <NoticeManager />
        },
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
