import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const companySlice = createSlice({
    name: '@company',
    initialState,
    reducers: {
        setCompany: (_, action) => action.payload,
        clearCompany: () => initialState
    }
});

export const { setCompany, clearCompany } = companySlice.actions;
export default companySlice.reducer;
