// assets
import { IconHome } from '@tabler/icons';

// constant
const icons = {
    IconHome
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'ploys',
    title: 'Empreendimentos',
    type: 'group',
    children: [
        {
            id: 'empreendimentos',
            title: 'Empreendimentos',
            icon: icons.IconHome,
            type: 'item',
            url: '/ploy',
            breadcrumbs: true
        }
    ]
};

export default pages;
