import { createSlice } from '@reduxjs/toolkit';
import pagedDefault from './constants';
import _uniqBy from 'lodash/uniqBy';
import _findIndex from 'lodash/findIndex';
import { insertItemArray } from '../../utils/utility';

const initialState = pagedDefault;

const userSlice = createSlice({
    name: '@person',
    initialState,
    reducers: {
        setListPerson: (state, action) => {
            const hasItems = action.payload.items.length > 0;
            if (hasItems) {
                const items = _uniqBy([...action.payload.items], 'code');
                return { ...state, ...action.payload, items };
            }
            return state;
        },
        updatePerson: (state, action) => {
            if (action.payload != null && action.payload.code !== null) {
                const index = _findIndex(state.items, { code: action.payload.code });
                const items = insertItemArray(state.items, index, action.payload);
                return { ...state, items: _uniqBy(items, 'code') };
            }
            return state;
        },
        updateImage: (state, action) => {
            if (action.payload != null && action.payload.code != null) {
                const index = _findIndex(state.items, { code: action.payload.code });
                if (index >= 0) {
                    const newState = { ...state.items[index], photoUrl: action.payload.photoUrl };
                    const items = insertItemArray(state.items, index, newState);
                    return { ...state, items: _uniqBy(items, 'code') };
                }
            }
            return state;
        },
        clearAllPersons: () => initialState
    }
});

export const { setListPerson, clearAllPersons, updatePerson, updateImage } = userSlice.actions;
export default userSlice.reducer;
