const Profiles = {
    Master: 1,
    Administrator: 2,
    Administrative: 3,
    ProviderService: 4,
    Customer: 5,
    Broker: 6,
    Partner: 7
};

export function routeByProfile(profileId) {
    switch (profileId) {
        case Profiles.Master:
        case Profiles.Administrator:
        case Profiles.Administrative:
        case Profiles.Broker:
            return 'employe';
        case Profiles.Customer:
        case Profiles.ProviderService:
            return 'customer';
        case Profiles.Partner:
            return 'partner';
        default:
            return 'person';
    }
}

export function profileTranslate(profileId) {
    switch (profileId) {
        case Profiles.Master:
            return 'Master';
        case Profiles.Administrator:
            return 'Administrador';
        case Profiles.Administrative:
            return 'Administrativo';
        case Profiles.Broker:
            return 'Corretor';
        case Profiles.Customer:
            return 'Cliente';
        case Profiles.ProviderService:
            return 'Prestador de Serviço';
        case Profiles.Partner:
            return 'Parceiro';
        default:
            return 'Cliente';
    }
}

export function hasPermissionManagerProfile(profileIds) {
    if (profileIds == null || profileIds.length === 0) {
        return false;
    }
    return [Profiles.Master, Profiles.Administrator].some((item) => profileIds.includes(item));
}

export default Profiles;
