import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import monitorReducer from './enhancers/monitorReducer';
import loggerMiddleware from './middleware/logger';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

let persistor = null;

export default function configureAppStore() {
    const store = configureStore({
        reducer: rootReducer,
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState: {},
        enhancers: [monitorReducer],
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
                }
            })
                .concat(loggerMiddleware)
                .concat(thunkMiddleware)
    });

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
    }

    persistor = persistStore(store);

    return { store, persistor };
}

export function getPersistor() {
    return persistor;
}
