import moment from 'moment';

export const FORMAT_DATE = 'DD/MM/YYYY';
export const ISO_FORMAT_DATE = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const EN_US_DATE = 'YYYY-MM-DD';

export function placeDots(string) {
    let iteration = -2;
    const arrayWithDots = [];

    for (let i = string.length - 1; i > -1; i -= 1) {
        if (iteration % 3 === 0 && i !== 0) {
            arrayWithDots.push(`.${string[i]}`);
        } else {
            arrayWithDots.push(string[i]);
        }

        iteration += 1;
    }

    return arrayWithDots.reverse().join('');
}

export function currencyParser(value, prefix = '', isAbsolute = false) {
    if (!value) {
        value = '0';
    }
    let stringValue = String(value) // eslint-disable-line
    let integers = [];
    let decimals = '';

    const sign = /-.+/.test(stringValue) ? '-' : '';

    stringValue = stringValue.replace(/-/g, '').replace(/,/g, '.').replace(prefix, '');

    if (isAbsolute) {
        const absoluteValues = stringValue.replace(/[^\d]/g, '');
        decimals = absoluteValues.substr(-2, 2);

        integers = placeDots(absoluteValues.slice(0, -2));

        return `${prefix}${sign}${integers},${decimals}`;
    }

    const splitedValues = stringValue.split('.');

    decimals = splitedValues.length > 1 ? splitedValues.pop() : '00';
    const integerValues = splitedValues.join('');

    integers = placeDots(integerValues);

    if (decimals.length < 2) {
        decimals = `${decimals}0`;
    } else if (decimals.length > 2) {
        decimals = decimals.substring(0, 2);
    }
    const valueParse = prefix + sign + integers;
    return `${valueParse || '0'},${decimals}`;
}

export function ISODateFormat(date, format = FORMAT_DATE) {
    if (!date) return null;
    return moment(date, ISO_FORMAT_DATE).locale('pt-br').format(format);
}

export const formatDate = (date, format = EN_US_DATE) => {
    if (!date) return null;

    if (format === EN_US_DATE) {
        date = moment(date, FORMAT_DATE);
    }
    return moment(new Date(date)).format(format);
};

export const formatDateIso = (date, format) => {
    const milliseconds = parseInt(moment(date, format).format('x'), 10);
    return new Date(milliseconds).toISOString();
};

export function ISODateCalcDiff(firstDate, secondDate) {
    if (!firstDate && !secondDate) return null;
    const calc = moment(secondDate, ISO_FORMAT_DATE).diff(moment(firstDate, ISO_FORMAT_DATE));
    const duration = moment.duration(calc);
    return moment.utc(duration.asMilliseconds()).format('HH:mm');
}

export function round2Decimal(value) {
    return Math.round(value * 100) / 100;
}

export function currencyToFloat(str) {
    if (!str) return parseFloat('0');
    try {
        let result = parseFloat(str).toFixed(2);
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(result)) {
            result = parseFloat(str?.toString().replace('R$', '').replace('.', '').replace(',', '.'));
        }
        return parseFloat(result);
    } catch (e) {
        return parseFloat(str?.toString().replace('R$', '').replace('.', '').replace(',', '.'));
    }
}

export function truncateString(str, num) {
    if (str.length <= num) {
        return str;
    }
    return `${str.slice(0, num)}...`;
}

export function ParseJsonObject(strData) {
    try {
        return JSON.parse(strData);
    } catch (e) {
        return {};
    }
}

export function insertItemArray(arr, index, newItem) {
    return [...arr.slice(0, index), newItem, ...arr.slice(index)];
}

export function ensureDecimals(value, decimalPlaces = 2, decimalSeparator = '.') {
    /**
     * Given a string or number returns a string with the decimals required
     * truncated or filled as needed.
     *
     * Examples:
     * 1 -> "1.00"
     * 1.2 -> "1.20"
     * 1.1278 -> "1.13"
     */
    let currentValue = Number(value).toFixed(decimalPlaces);
    const decimalPlacesInt = parseInt(decimalPlaces, 10);

    if (decimalPlacesInt > 0) {
        // we cannot use toFixed here, because some rounding problems will arise
        currentValue = currentValue.toString();
        currentValue = currentValue.replace('.', decimalSeparator);
        let decimalPos = currentValue.indexOf(decimalSeparator);
        if (decimalPos === -1) {
            currentValue += decimalSeparator;
        }
        currentValue += '0'.repeat(decimalPlacesInt);
        decimalPos = currentValue.indexOf(decimalSeparator);
        currentValue = currentValue.slice(0, decimalPos + (decimalPlacesInt + 1));
    }
    return String(currentValue);
}
