import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: null,
    isLogged: false,
    isFetching: false,
    person: {},
    apiToken: {
        accessToken: null,
        refreshToken: null
    }
};

const userSlice = createSlice({
    name: '@user',
    initialState,
    reducers: {
        setUser: (state, action) => ({ ...state, isLogged: true, ...action.payload }),
        clearUser: () => initialState
    }
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
