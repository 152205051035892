import apiService from './apiService';
import { clearUser, setUser } from 'store/reducers/userReducer';
import { clearPloys } from 'store/reducers/ployReducer';
import { clearCompany } from 'store/reducers/companyReducer';
import { clearMessage } from 'store/reducers/dialogReducer';
import { clearCampaign } from 'store/reducers/campaignReducer';
import { clearNotice } from 'store/reducers/noticeReducer';
import { clearAllPersons } from 'store/reducers/personReducer';
import { CLEAR_CUSTOMIZATION } from 'store/actions';

/**
 * Fetch login user
 * @param {JSON}  data
 * @returns {JSON} JSON
 */
export function fetchLogin(data) {
    return async (dispatch, getState) => {
        try {
            const response = await apiService(getState()).post('/api/v1/BackofficeAuthentication', data);
            if (response) {
                dispatch(setUser(response));
            }
            return response;
        } catch (ex) {
            console.error('fetchLogin', ex.message);
            return null;
        }
    };
}

export function handlerLogOut() {
    return async function handlerLogOutThunk(dispatch) {
        try {
            dispatch({ type: CLEAR_CUSTOMIZATION });
            dispatch(clearAllPersons());
            dispatch(clearNotice());
            dispatch(clearCampaign());
            dispatch(clearPloys());
            dispatch(clearMessage());
            dispatch(clearCompany());
            dispatch(clearUser());
        } catch (ex) {
            console.error('handlerLogOut', ex.message);
            throw ex;
        }
    };
}
