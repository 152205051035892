import axios from 'axios';

export default function apiService(state, dispatch = null) {
    const { user, company } = state;
    const {
        apiToken: { accessToken, refreshToken }
    } = user;
    const code = company?.code || process.env.REACT_APP_X_COMPANY_CODE;
    const token = !accessToken ? company?.apiToken?.accessToken : accessToken;

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 60000,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY,
            'x-company-code': code,
            'x-platform': 'web',
            Authorization: `bearer ${token}`
        }
    });

    function requestHandler(request) {
        return request;
    }

    function responseHandler(response) {
        if (response.status === 200) {
            return response?.data;
        }
        if (response.status === 401) {
            // dispatch && dispatch(refreshUserToken(refreshToken));
            return Promise.reject(new Error('Ocorreu um erro, tente novamente'));
        }
        console.debug(response);
        return Promise.reject(response);
    }

    function errorHandler(error) {
        let message = null;
        switch (error?.response?.status) {
            case 500:
                message = 'Estamos enfrentando problemas, tente novamente mais tarde';
                break;
            case 401:
                message = 'Ocorreu um erro, tente novamente';
                // dispatch && dispatch(refreshUserToken(refreshToken));
                break;
            default:
                break;
        }
        if (error.response && error.response.data && error.response.data.errors && Array.isArray(error.response.data.errors)) {
            if (error.response.data.errors.length > 0) {
                message = error.response.data.errors[0].errorMessage;
            }
        }
        if (!message && error.message) {
            if (error.message.toLowerCase() === 'network error') {
                message = 'Sem conexão com a internet';
            } else {
                message = error.message;
            }
        }
        return Promise.reject(message ? new Error(message) : error);
    }

    api.interceptors.request.use(
        (request) => requestHandler(request),
        (error) => errorHandler(error)
    );

    api.interceptors.response.use(
        (response) => responseHandler(response),
        (error) => errorHandler(error)
    );

    return api;
}
