import { createSlice } from '@reduxjs/toolkit';
import pagedDefault from './constants';

const initialState = pagedDefault;
const ploySlice = createSlice({
    name: '@ploy',
    initialState,
    reducers: {
        setListPloys: (state, action) => {
            const hasItems = action.payload.items.length > 0;
            if (hasItems) {
                const newState = { ...state, ...action.payload };
                return { ...newState };
            }
            return initialState;
        },
        clearPloys: () => initialState
    }
});

export const { setListPloys, clearPloys } = ploySlice.actions;
export default ploySlice.reducer;
