import { createSlice } from '@reduxjs/toolkit';
import pagedDefault from './constants';

const initialState = pagedDefault;
const servicesSlice = createSlice({
    name: '@services',
    initialState,
    reducers: {
        setListServices: (state, action) => {
            const hasItems = action.payload.items.length > 0;
            if (hasItems) {
                return { ...state, ...action.payload };
            }
            return initialState;
        },
        clearServices: () => initialState
    }
});

export const { setListServices, clearServices } = servicesSlice.actions;
export default servicesSlice.reducer;
