import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import ploy from './ploy';
import manager from './manager';
import humanResources from './humanResources';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, manager, ploy, pages, utilities, other]
};

export default menuItems;
