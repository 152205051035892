import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import configureAppStore from './store';
import Loader from './ui-component/Loader';
// style + assets
import 'assets/scss/style.scss';
import { useEffect } from 'react';

const { store, persistor } = configureAppStore();
// ==============================|| REACT DOM RENDER  ||============================== //

function AppWithCallbackAfterRender() {
    useEffect(() => {});

    return (
        <Provider store={store}>
            <PersistGate loading={<Loader />} persistor={persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />);
serviceWorker.unregister();
