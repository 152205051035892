import { createSlice } from '@reduxjs/toolkit';
import pagedDefault from './constants';

const initialState = pagedDefault;
const ploySlice = createSlice({
    name: '@notice',
    initialState,
    reducers: {
        setListNotice: (state, action) => {
            const hasItems = action.payload.items.length > 0;
            if (hasItems) {
                return { ...state, ...action.payload };
            }
            return initialState;
        },
        clearNotice: () => initialState
    }
});

export const { setListNotice, clearNotice } = ploySlice.actions;
export default ploySlice.reducer;
