const pagedDefault = {
    items: [],
    pages: [],
    currentPage: 1,
    totalItems: 0,
    pageSize: 1000,
    startPage: 1,
    endPage: 0,
    startIndex: -1000,
    endIndex: -1,
    totalPages: 0
};

export default pagedDefault;
