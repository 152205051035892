// assets
import Badge from '@mui/icons-material/Badge';
import People from '@mui/icons-material/People';
import Handshake from '@mui/icons-material/Handshake';

// constant
const icons = {
    Badge,
    People,
    Handshake
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'humanResources',
    title: 'Gestão de Pessoas',
    type: 'group',
    children: [
        {
            id: 'persons',
            title: 'Pessoas',
            type: 'item',
            icon: icons.People,
            url: '/person',
            breadcrumbs: true
        }
        // {
        //     id: 'customer',
        //     title: 'Clientes',
        //     icon: icons.People,
        //     type: 'item',
        //     url: '/customer',
        //     breadcrumbs: false
        // },
        // {
        //     id: 'partner',
        //     title: 'Parceiros',
        //     icon: icons.Handshake,
        //     type: 'item',
        //     url: '/partner',
        //     breadcrumbs: false
        // }
    ]
};

export default pages;
