// assets
import CampaignIcon from '@mui/icons-material/Campaign';
import Newspaper from '@mui/icons-material/Newspaper';
import People from '@mui/icons-material/People';

// constant
const icons = {
    CampaignIcon,
    Newspaper,
    People
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'manager',
    title: 'Gerenciar',
    type: 'group',
    children: [
        {
            id: 'campanhas',
            title: 'Campanhas',
            icon: icons.CampaignIcon,
            type: 'item',
            url: '/campaign',
            breadcrumbs: true
        },
        {
            id: 'notices',
            title: 'Notícias',
            icon: icons.Newspaper,
            type: 'item',
            url: '/notice',
            breadcrumbs: true
        },
        {
            id: 'persons',
            title: 'Pessoas',
            type: 'item',
            icon: icons.People,
            url: '/person',
            breadcrumbs: true
        }
    ]
};

export default pages;
