import { combineReducers } from 'redux';
import customizationReducer from './customizationReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './userReducer';
import companyReducer from './companyReducer';
import ployReducer from './ployReducer';
import dialogReducer from './dialogReducer';
import campaignReducer from './campaignReducer';
import noticeReducer from './noticeReducer';
import personReducer from './personReducer';
import servicesReducer from './servicesReducer';
import commonsReducer from './commonsReducer';

const persistConfig = {
    key: 'root',
    rehydrated: false,
    storage
};

const reducer = combineReducers({
    campaign: campaignReducer,
    commons: commonsReducer,
    company: companyReducer,
    customization: customizationReducer,
    dialog: dialogReducer,
    notice: noticeReducer,
    person: personReducer,
    ploy: ployReducer,
    services: servicesReducer,
    user: userReducer
});

export default persistReducer(persistConfig, reducer);
