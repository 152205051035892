import { createSlice } from '@reduxjs/toolkit';
import InstallmentJson from 'assets/files/InstallmentReceipt.json';

const initialState = {};

const commonsSlice = createSlice({
    name: '@commons',
    initialState,
    reducers: {
        loadInstallmentReceipt: () => InstallmentJson,
        clearCompany: () => initialState
    }
});

export const { loadInstallmentReceipt, clearCommon } = commonsSlice.actions;
export default commonsSlice.reducer;
