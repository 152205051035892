import apiService from './apiService';
import { setCompany } from '../store/reducers/companyReducer';

/**
 * Fetch to company data
 * @async
 * @function
 * @return {Promise<JSON>} Object company data
 */
// eslint-disable-next-line import/prefer-default-export
export function fetchCompany() {
    return async function fetchCompanyThunk(dispatch, getState) {
        try {
            const response = await apiService(getState()).get('/api/v1/company');
            if (response) {
                await dispatch(setCompany(response));
            }
            return response;
        } catch (ex) {
            console.error('fetchCompany', ex.message);
            return null;
        }
    };
}
