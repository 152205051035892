import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    type: 'information',
    title: null,
    message: null,
    buttons: [
        { title: 'Ok', actionButton: null, closeDialog: true },
        { title: 'Cancelar', actionButton: null, closeDialog: true }
    ]
};
const ploySlice = createSlice({
    name: '@dialog',
    initialState,
    reducers: {
        setMessage: (state, action) => ({ ...state, ...action.payload }),
        clearMessage: () => initialState
    }
});

export const { setMessage, clearMessage } = ploySlice.actions;
export default ploySlice.reducer;
