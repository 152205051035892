import { createSlice } from '@reduxjs/toolkit';
import pagedDefault from './constants';

const initialState = pagedDefault;
const ploySlice = createSlice({
    name: '@campaign',
    initialState,
    reducers: {
        setListCampaign: (state, action) => {
            const hasItems = action.payload.items.length > 0;
            if (hasItems) {
                return { ...state, ...action.payload };
            }
            return initialState;
        },
        clearCampaign: () => initialState
    }
});

export const { setListCampaign, clearCampaign } = ploySlice.actions;
export default ploySlice.reducer;
