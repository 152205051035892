import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { clearMessage } from 'store/reducers/dialogReducer';

export default function ResponsiveDialog() {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();

    const dialog = useSelector((state) => state.dialog);
    const { type, title, message, buttons } = dialog;

    const handleClose = () => {
        dispatch(clearMessage());
        setOpen(false);
    };

    function handleClick(button) {
        const { actionButton, closeDialog } = button;
        if (actionButton != null) {
            actionButton(button);
        }
        if (closeDialog) {
            dispatch(clearMessage());
        }
        setOpen(false);
    }
    useEffect(() => {
        if (message != null) {
            setOpen(true);
            return;
        }
        setOpen(false);
    }, [message]);

    function getMessage() {
        return message?.replace(/\n/g, '<br />');
    }

    function renderButtons() {
        if (buttons != null) {
            if (type === 'information') {
                const button = buttons[0];
                return (
                    <Button autoFocus onClick={() => handleClick(button)}>
                        {button.title}
                    </Button>
                );
            }
            return buttons.map((button) => (
                <Button key={`button_${button.title}`} autoFocus onClick={() => handleClick(button)}>
                    {button.title}
                </Button>
            ));
        }
        return (
            <Button autoFocus onClick={() => setOpen(false)}>
                OK
            </Button>
        );
    }

    return (
        <div>
            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{getMessage()}</DialogContentText>
                </DialogContent>
                <DialogActions>{message && renderButtons()}</DialogActions>
            </Dialog>
        </div>
    );
}
